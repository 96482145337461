import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import i18n from "i18next";
import { SITE_URL } from "../constants";
import { useSearchParams } from "react-router-dom";

export function Header(props) {
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  let articleType = searchParams.get("name");

  return (
    <>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div
                  className="owl-carousel owl-carousel-1 tranding-carousel position-relative d-inline-flex align-items-center ml-3 owl-loaded owl-drag"
                  style={{ width: "calc(100% - 100px)", paddingLeft: 90 }}
                >
                  {/* <div class="text-truncate"><Link class="text-secondary" to=""> AR in Education: Augment Your Learning with Fun and Technolo</Link></div>*/}
                  {/* <div class="text-truncate"><Link class="text-secondary" to=""> AR in Education: Augment Your Learning with Fun and Technolo</Link></div>*/}
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        // transform: "translate3d(-1116px, 0px, 0px)",
                        transition: "all 2s ease 0s",
                        width: 3350,
                      }}
                    >
                      <div
                        className="owl-item cloned"
                        style={{ width: "558.219px" }}
                      >
                        <div className="text-truncate">
                          <Link
                            className="text-secondary"
                            to={SITE_URL + "/details?articleNumber=17"}
                          >
                            {/* શૂરવીર અને ખમીરવંતા જસદણ ના મણીબેન કોલસવાળા ની રશપ્રદ
                          વાત */}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="owl-nav">
                  <div className="owl-prev">
                    <i className="fa fa-angle-left" />
                  </div>
                  <div className="owl-next">
                    <i className="fa fa-angle-right" />
                  </div>
                </div> */}
                  <div className="owl-dots disabled" />
                </div>
              </div>
            </div>
            <div
              id="date"
              className="col-md-4 text-right d-none d-md-block"
            ></div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center py-2">
            <div className="col-lg-4">
              <Link to={SITE_URL} className="navbar-brand d-none d-lg-block">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/logo_h_" +
                    i18n.language +
                    ".webp"
                  }
                  width={200}
                  alt="Trying to spreading Positivity"
                />
              </Link>
            </div>
            <div className="col-lg-8 text-center text-lg-right">
              <div className="d-flex flex-wrap m-n1" style={{ float: "right" }}>
                <LanguageSelector />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 mb-3 bg-light">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light py-2 py-lg-0">
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse justify-content-between px-0"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto py-0">
                <Link
                  to={SITE_URL}
                  className={
                    path === "/"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                >
                   <img src={SITE_URL+"/img/home_222306.png"} height="25" alt={t("home")} />
                </Link>
                {/* <Link
                  to={SITE_URL + "/About"}
                  className={
                    path === "/about"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                >
                  {t("aboutUs")}
                </Link> */}
                <Link
                  to={SITE_URL + "/category?name=N"}
                  className={
                    path === "/category" && articleType === "N"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                >
                  {t("positiveNews")}
                </Link>
                <Link
                  to={SITE_URL + "/category?name=P"}
                  className={
                    path === "/category" && articleType === "P"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                >
                  {t("parmarth")}
                </Link>
                <Link
                  to={SITE_URL + "/category?name=R"}
                  className={
                    path === "/category" && articleType === "R"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                >
                  {t("purusharth")}
                </Link>
                {/*                  
                    <Link to="single.html" class="nav-item nav-link">Single News</Link>
                <div class="nav-item dropdown">
                    <Link to="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Dropdown</Link>
                    <div class="dropdown-menu rounded-0 m-0">
                        <Link to="#" class="dropdown-item">Menu item 1</Link>
                        <Link to="#" class="dropdown-item">Menu item 2</Link>
                        <Link to="#" class="dropdown-item">Menu item 3</Link>
                    </div>
                </div>
  */}
                <Link
                  to={SITE_URL + "/Contact"}
                  className={
                    path === "/contact"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                >
                  {t("contact")}
                </Link>
              </div>
              {/* <div class="input-group ml-auto" style="width: 100%; max-width: 300px;">
                <input type="text" class="form-control" placeholder="Keyword">
                <div class="input-group-append">
                    <button class="input-group-text text-secondary"><i
                            class="fa fa-search"></i></button>
                </div>
            </div> */}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
